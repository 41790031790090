import { createRouter, createWebHistory, type RouteRecordRaw, type Router } from 'vue-router'
import { loginRequest, msalInstance } from './msalConfig'
import useNotifications from './hooks/useNotifications'
import { InteractionType, type PopupRequest, type PublicClientApplication, type RedirectRequest, NavigationClient, type NavigationOptions } from '@azure/msal-browser'

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
  private readonly router: Router

  constructor (router: Router) {
    super()
    this.router = router
  }

  /**
     * Navigates to other pages within the same web application
     * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
     * @param url
     * @param options
     */
  async navigateInternal (url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '')
    if (options.noHistory) {
      await this.router.replace(relativePath)
    } else {
      await this.router.push(relativePath)
    }

    return false
  }
}

export const routes: RouteRecordRaw[] = [
  { name: 'home', path: '/', component: async () => await import('./views/Home.vue') },

  { name: 'accreditation-cards-select-event', path: '/accreditation-cards', component: async () => await import('./views/accreditation-cards/ModelSelection.vue'), meta: { requiresAuth: true, menu: 'Accreditation Cards' } },
  { name: 'accreditation-cards-create-cards', path: '/accreditation-cards/:model', component: async () => await import('./views/accreditation-cards/CreateCards.vue'), meta: { requiresAuth: true } },

  { name: 'access-scanner', path: '/access-scanner', component: async () => await import('./views/access-scanner/AccessScanner.vue'), meta: { menu: 'Access Scanner' } },
  { name: 'access-scanner-shareable-configs', path: '/access-scanner/configs', component: async () => await import('./views/access-scanner/ShareableConfigs.vue'), meta: { menu: 'Access Scanner - Shareable Configs' } },

  { name: 'assign-slots-select-event', path: '/assign-slots', component: async () => await import('./views/assign-slots/ModelSelection.vue'), meta: { requiresAuth: true, menu: 'Assign Slots' } },
  { name: 'assign-slots-assign', path: '/assign-slots/:model', component: async () => await import('./views/assign-slots/AssignSlots.vue'), meta: { requiresAuth: true } },

  { name: 'billing-reports-select-event', path: '/billing-reports', component: async () => await import('./views/billing-reports/ModelSelection.vue'), meta: { requiresAuth: true, menu: 'Billing Reports' } },
  { name: 'billing-reports-generate', path: '/billing-reports/:model', component: async () => await import('./views/billing-reports/GenerateReports.vue'), meta: { requiresAuth: true } },

  { name: 'not_found', path: '/:catchAll(.*)*', component: async () => await import('./views/404.vue') }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

async function isAuthenticated (instance: PublicClientApplication, interactionType: InteractionType, loginRequest: PopupRequest | RedirectRequest): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return await instance.handleRedirectPromise().then(async () => {
    const accounts = instance.getAllAccounts()
    if (accounts.length > 0) {
      return true
    }

    // User is not signed in and attempting to access protected route. Sign them in.
    if (interactionType === InteractionType.Popup) {
      return await instance.loginPopup(loginRequest).then(() => {
        return true
      }).catch(() => {
        return false
      })
    } else if (interactionType === InteractionType.Redirect) {
      return await instance.loginRedirect(loginRequest).then(() => {
        return true
      }).catch(() => {
        return false
      })
    }

    return false
  }).catch(() => {
    return false
  })
}

router.beforeEach(async (to, from) => {
  await msalInstance.initialize()
  const notifications = useNotifications()
  if (to.meta.requiresAuth) {
    const request = {
      ...loginRequest,
      redirectStartPage: to.fullPath
    }
    const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request)
    if (!shouldProceed) {
      notifications.push({
        message: 'Authentication failed',
        code: 'auth-failed',
        color: 'red'
      })
    }
    return shouldProceed
  }

  return true
})

export default router
